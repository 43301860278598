<template>
  <div>
    <CRow >
      <CCol md="5">
        <CRow>
          <div class="c-avatar"  style="height: 240px; width: 240px; margin: auto;">
            <img :src="objMarketsById.creator.userinfo.image" class="c-avatar-img " width="100%" height="100%" />
          </div>
        </CRow>
        <CRow>
          <CCol col="12" class="text-center mt-4">
            <h4>{{objMarketsById.creator.displayName}}</h4>
          </CCol>
          <CCol col="12">
            <div v-html="objMarketsById.creator.bio.about"/>
          </CCol>
        </CRow>
      </CCol>
      <CCol md="7" >
        <CCard accent-color="success" >
          <CCardHeader>
            <div class="card-header-actions">

              <CRow>
                <CCol class="text-right mt-2">
                  <div class="float-right">
                    <CButton color="transparent p-0" class="float-left mr-2"  @click="onLike">
                      <span class="mr-2 text-white">{{(objMarketsById.like != null)?objMarketsById.like.length:0}}</span>
                      <CIcon  size="lg" name="cil-heart" class="text-white"/>
                    </CButton>
                    <div class="float-left" >
                      <sharing :sharing="objMarketsById.sharing"/>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol class="text-center">
                <div class="position-relative mb-4" >
                  <div class="overflow-auto m-auto text-center" >
                    <img class="w-100" :src="objMarketsById.image" />
                  </div>

                  <div class="pt-2 pr-2 pl-2 mt-2 mb-2 mr-2 position-absolute bg-gray-500" style="bottom: 0px; right: 0px; border-radius: 5px">
                    <flip-countdown :deadline="moment(objMarketsById.expiresAt).add('-7','hour').format('YYYY-MM-DD HH:mm:ss')"></flip-countdown>

                  </div>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <h5>Name : {{ objMarketsById.name }}</h5>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div class="mb-2">
                  <span class="font-weight-bold"> Categorie :  </span>
                  <span class="text-white">{{objMarketsById.categorie.title}}</span>
                </div>
                <div class="mb-2">
                  <span class="font-weight-bold"> Price :  </span>
                  <span class="text-white">{{(objMarketsById.priceInWen != null)?objMarketsById.priceInWen:0}} NEXT / USD</span>
                </div>
              </CCol>
            </CRow>

            <CRow >
              <CCol col="12" class="mt-2">
                <h6> <CIcon name="cil-notes" class="mr-2 font-weight-bold"/> Decription</h6>
              </CCol>
              <CCol col="12" class="mt-2">
                <h6>{{ objMarketsById.description }}</h6>
              </CCol>
            </CRow>
            <CRow class="mt-4">
              <CCol col="12" >
                <h6> <CIcon name="cil-contact" class="mr-2 font-weight-bold"/> Details</h6>
              </CCol>
              <CCol col="12" class="mt-2">
                <CRow>
                  <CCol> <h6>Contract Address</h6>  </CCol>
                  <CCol class="text-right"> <h6>{{objMarketsById.contactAddress}}</h6> </CCol>
                </CRow>
                <CRow>
                  <CCol> <h6>Token ID</h6>  </CCol>
                  <CCol class="text-right"> <h6>{{objMarketsById.tokenId}}</h6> </CCol>
                </CRow>
                <CRow>
                  <CCol> <h6>Blockchain</h6>  </CCol>
                  <CCol class="text-right"> <h6>BSC</h6> </CCol>
                </CRow>
                <CRow>
                  <CCol> <h6>Royalty</h6>  </CCol>
                  <CCol class="text-right"> <h6>{{objMarketsById.priceInCreator}} %</h6> </CCol>
                </CRow>
                <CRow>
                  <CCol> <h6>Creater</h6>  </CCol>
                  <CCol class="text-right"> <h6>{{objMarketsById.creator.displayName}}</h6> </CCol>
                </CRow>
                <CRow>
                  <CCol> <h6>Onwer</h6>  </CCol>
                  <CCol class="text-right"> <h6>{{objMarketsById.onwer.displayName}}</h6> </CCol>
                </CRow>
                <CRow>
                  <CCol> <h6>Create Date</h6>  </CCol>
                  <CCol class="text-right"> <h6>{{objMarketsById.createDate}}</h6> </CCol>
                </CRow>

              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol>
<!--                <CCol>-->
<!--                  <CButton  class="w-100" color="success"  @click="onApprove" > Approve </CButton>-->
<!--                </CCol>-->
                <CButton @click="onBlack" color="light" class=" w-100">Cancel</CButton>
              </CCol>
              <CCol>
                <CButton v-if="!isApptoveBuyNft" class="w-100" color="success"  @click="onApprove" > Approve </CButton>
                <CButton v-if="isApptoveBuyNft"  class="w-100" color="success"  @click="onBuyNow" > Buy Now </CButton>
<!--                <CButton @click="onBuyNow" color="success" class=" w-100">Buy Now</CButton>-->
              </CCol>
            </CRow>
          </CCardBody>

        </CCard>
      </CCol>
    </CRow>
<!--    <MetamaskConnect user-message="msg" @onComplete="onComplete"/>-->


  </div>
</template>

<script>

import {ABI, address, addressMaket} from "@/store/modules/Metamask/constants/escrowContract";
import Web3 from "web3";
  import {mapGetters} from 'vuex'
  import Vue from 'vue'
  import VueQuillEditor from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
import DialogConsistency from "@/project/views/pages/dialog/DialogConsistency";
import Sharing from "@/project/views/util/sharing";
import FlipCountdown from "@/project/containers/utils/FlipCountdown";
  Vue.use(VueQuillEditor)
export default {
  name: 'MarketDetail',
  components: {FlipCountdown, Sharing, DialogConsistency},
  data: function () {
    return {
      url:"img/token/nextart.png",
      cataigory:[{
        text:"asss",
        value:1
      }],
      decrption:{
        contactAddress:"xxxx",
        tokenId:"",
        blockchain:"",
        metadata:"",
        createDate:""
      }
    }
  },
  mounted () {

  },

  created () {

    this.$store.commit("metamask/setisApptove", false);
    var body = {};
    body.id = this.$route.query.id;
    this.$store.dispatch('gateway/onMarketsById',body);

  },

  beforeDestroy () {

  },

  methods: {

    onBlack(){
      this.$router.push('/home')
    },


    onApprove(){
      var obj = {};
      obj.contactAddress = address;
      obj.spender = addressMaket;
      obj.value = this.objMarketsById.priceInWen;

      this.$store.dispatch('metamask/onContractApprove',obj)

    },

    onBuyNow(){
      var obj = {};
      obj.nftAddress = this.objMarketsById.contactAddress;
      obj.tokenId = this.objMarketsById.tokenId;
      obj.priceInWen = this.objMarketsById.priceInWen;
      console.log(obj)
      this.$store.dispatch('eRCMarketPlace/onExecuteOrderToMarkets',obj)
    }

  },

  computed:{
    ...mapGetters({
      objMarketsById:'gateway/objMarketsById',
      isApptoveBuyNft: "metamask/objisApptove",
    })
  },

  watch: {
    objMarketsById(value){
      console.log("objMarketsById",value)

      // document.title = value.name;
      // document.images = value.image;
      // document.type = value.cataigory;
    },
  }
}
</script>
